import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { Link, animateScroll as scroll } from 'react-scroll';
import {post} from 'axios';

import { RichText } from 'prismic-reactjs';

import { Layout, SEO, Grid, Box, Text } from 'components';
import Input from 'components/events/Input';
import { colors, mediaQueries } from 'styles/theme';
import Loader from 'components/Loader';

import ForwardsLogo from 'images/forwards-logo.svg';
import ForwardsLogoMobile from 'images/forwards-logo-mobile.svg';
import DeleteIcon from 'images/delete-icon.svg';

const TextContainer = Text.withComponent('div');

const IntroContainer = styled(Box)`
  width: 100%;
  position: relative;
  padding: 5% 10%;
  // max-height: 600px;
`;

const Intro = styled(Box)`
  width: 100%;
  padding-bottom: calc(1440 / 1440 * 100%);
  position: relative;

  ${mediaQueries.md} {
    padding-bottom: calc(600 / 1440 * 100%);
  }
  .content {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;

    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-out;

    background-image: url(${ForwardsLogoMobile});
    background-repeat: no-repeat;
    background-position: center center ;
    background-size: 100% 100%;

    ${mediaQueries.md} {
      background-image: url(${ForwardsLogo});
    }

    ${mediaQueries.xxl} {
      height: 818px;
    }
  }

  ${mediaQueries.xxl} {
    padding-bottom: 39.50%
  }
`;

const WelcomeContainer = styled(Grid)`
  ${Box} {
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.02);

    ${mediaQueries.md} {
      border-radius: 32px;
    }
  }
`;

const FormContainer = styled(Box)``;

const SocialHeader = styled(Text)`
  text-transform: uppercase;
  grid-column: ${['2 / span 10']};
  text-align: center;
  padding-bottom: 35px;
`;

SocialHeader.defaultProps = {
  size: ['1.1x', '0.8x'],
};

const RsvpButton = styled(Text)`
  display: inline-block;
  background-color: ${colors.black};
  border-radius: 20px;
  color: ${colors.white};
  padding: 18px 25px;
  cursor: pointer;
  position: relative;
  margin: 0 auto;

  &::after {
    content: '\\2193';
    display: inline-block;
    font-size: inherit;
    margin-left: 10px;
  }
`;

const RsvpForm = styled(Box)`
  // position: absolute;
  // z-index: 100;
  // top: -105px;
  // left: 0;
  // right: 0;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 32px;
  padding: 48px 24px;
  width: 100%;
  max-width: 460px;
  margin: 0 auto;

  ${mediaQueries.sm} {
    top: 0;
  }
`;

const RsvpSubmitBtn = styled(Box)`
  display: inline-block;
  border-radius: 20px;
  background-color: ${colors.gray};
  color: ${colors.black};
  padding: 18px 22px;
  cursor: pointer;
  position: relative;
  font-size: 1rem;

  margin-top: 32px;

  &::${ props => props.close ? 'before' : 'after'} {
    display: inline-block;
    width: 20px;
    height: 20px;
    ${ props => props.close
      ? `
          background-image: url('${DeleteIcon}');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          background-origin: content-box;

        `
      : `content: '\\2192';`
    }
  }
`;

class Forwards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formEnabled: false,
      formLoading: false,
      formData: {},
      formErrors: [],
      formSubmitted: false,
    };

    this.onInputUpdate = this.onInputUpdate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  checkForm() {
    const { first_name, last_name, email } = this.state.formData;
    const formErrors = [];

    if ( !first_name ) formErrors.push('first_name');
    if ( !last_name ) formErrors.push('last_name');
    if ( !email ) formErrors.push('email');

    this.setState({ formErrors });

    return formErrors;
  }

  onInputUpdate(id, val) {
    const {formData} = this.state;
    this.checkForm();

    this.setState({
      formData: {
        ...formData,
        [id]: val,
      },
    });
  }

  async onSubmit() {
    const { first_name, last_name, company, email } = this.state.formData;
    const errors = this.checkForm();

    if ( errors.length > 0) {
      return;
    }

    const url = 'https://api.b-reel.com/v1/events/forwards/rsvps';
    this.setState({formLoading: true });

    await post(url, {
      first_name,
      last_name,
      email,
      metadata: {
        company,
      }
    });

    this.setState({formSubmitted: true, formLoading: false });
  }

  render() {
    // const { data, section } = this.state;
    const metaData = {
      title: RichText.asText([{ text: 'B-Reel — Forwards' }]),
      description: RichText.asText([{
        text: "A free work session for brand and business leaders to find focus and drive action in a time of change."
      }]),
      themeColor: '#000'
    };

    const { formSubmitted, formErrors, formLoading } = this.state;

    return (
      <Layout>
        <SEO {...metaData} />
        <IntroContainer>
          <Intro>
            <div className="content"></div>
          </Intro>

        </IntroContainer>
        <Grid>
          <Box gridColumn={['1 / -1']} textAlign="center" pb={[3, 6]}>
            <Link
              to="signup-form"
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
            >
            <RsvpButton >Sign Up</RsvpButton>
            </Link>
          </Box>
        </Grid>

        <WelcomeContainer>
          <Box gridColumn={['1 / -1', '3 / span 8']} textAlign="left" mt={[6]} py={[3, 5]} px={[2, 7]}>
            <TextContainer size={['0.8x', '0.6x']} py={[2]}>
              For the last 20 years, we've made it our business to help build modern brands
              through our understanding of culture and technology. With the current state
              of change, we want to extend a friendly offer to help you keep pace.
            </TextContainer>
            <TextContainer size={['0.8x', '0.6x']} py={[2]}>
              Led by our Global Chief Strategy Officer, Melissa Jackson-Parsey, we've created
              two complimentary 90 minute sessions to create a safe and collaborative environment
              where you can step-back, organize your thoughts, and refocus your strategy.
            </TextContainer>
            <TextContainer size={['0.8x', '0.6x']} py={[2]}>
              These are facilitated, confidential and open to 1-4 leaders from your organization:
            </TextContainer>
            <TextContainer size={['0.8x', '0.6x']} py={[2]}>
              Session 1: Provide clarity for your team by guiding you through frameworks to explore
              your collective ambitions; the changes you need to act on; and most importantly,
              who you're for and what they need.
            </TextContainer>
            <TextContainer size={['0.8x', '0.6x']} py={[2]}>
              Session 2: Guide action by exploring what supports and blocks your ambitions today,
              and what else you could do to tap into bigger cultural shifts and emergent technologies.
            </TextContainer>
            <TextContainer size={['0.8x', '0.6x']} py={[2]}>
              Sign-up using the form below.
            </TextContainer>
          </Box>
        </WelcomeContainer>

        <Grid>
          <FormContainer  gridColumn={['1 / -1', '5 / -5' ]} py={[3, 6]} width="100%" textAlign="center">
            <RsvpForm id="signup-form">
              { !formSubmitted
                ? <div>
                    <Text size="1x" pb="2">Sign Up</Text>
                    <Input
                      label=""
                      theme="light-alt"
                      placeholder="First Name *"
                      id="first_name"
                      error={formErrors.includes('first_name')}
                      onUpdate={this.onInputUpdate}
                    />
                    <Input
                      label=""
                      theme="light-alt"
                      placeholder="Last Name *"
                      id="last_name"
                      error={formErrors.includes('last_name')}
                      onUpdate={this.onInputUpdate}
                    />
                    <Input
                      label=""
                      theme="light-alt"
                      placeholder="Company" id
                      ="company"
                      onUpdate={this.onInputUpdate}
                    />
                    <Input
                      label=""
                      theme="light-alt"
                      placeholder="E-Mail *"
                      id="email"
                      error={formErrors.includes('email')}
                      onUpdate={this.onInputUpdate}
                    />
                    { formLoading
                      ? <Loader />
                      : <RsvpSubmitBtn onClick={this.onSubmit}>Signup</RsvpSubmitBtn>
                    }
                  </div>
                : <div>
                    <Text size="1x" pb="2">Thank you for your RSVP! We'll be in touch soon.</Text>
                  </div>
              }
            </RsvpForm>



          </FormContainer>
        </Grid>
      </Layout>
    );
  }
};

export default Forwards;


