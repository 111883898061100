import styled from '@emotion/styled';
import { colors } from 'styles/theme';

const Loader = styled('div')`
  margin: 20px;
  height: 24px;
  width: 24px;
  color: ${colors.gray};
  position: relative;
  display: inline-block;
  border: 3px solid;
  border-radius: 50%;
  border-right-color: #5a5a5a;
  animation: rotate 1s linear infinite; }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg); }
    }
`;

export default Loader;