import React from 'react';
import styled from '@emotion/styled';
import { colors } from 'styles/theme';
import { Box } from 'components';

const InputBox = styled(Box)`

`;
const InputField = styled('input')`
  border-radius: 12px;
  border: 1px ${colors.blackDimmed} solid;
  padding: 15px;
  font-size: 0.9rem;
  width: 100%;

  &:focus {
    // border: 1px ${colors.black} solid;
    outline: none;
  }

  &.light {
    color: ${colors.blackDimmed};
    border-color: ${colors.gray};

    &:focus {
      background-color: ${colors.gray};
    }
  }

  &.dark {
    color: ${colors.whiteDimmed01};
    border-color: ${colors.whiteDimmed01};
    background-color: ${colors.black};

  }

  &.light-alt {
    color: ${colors.blackDimmed};
    background-color: rgba(0, 0, 0, 0);
    border-color: ${colors.gray};

    &:focus {
      color: ${colors.blackDimmed};

      background-color: ${colors.white};
    }
  }

  &.error {
    border-color: ${colors.red};
  }
`;

const InputLabel = styled('label')`
  font-size: 1rem;
  padding-bottom: 8px;
  display: block;

  &.error {
    color: ${colors.red};
  }
`;

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    }

    this.onChange = this.onChange.bind(this);
  }


  onChange(evt) {
    const { value } = evt.target;
    const { id, onUpdate } = this.props;

    this.setState({ value });

    if (onUpdate) onUpdate(id, value);
  }

  render() {
    const { label, id, gridColumn, placeholder, theme, error } = this.props;
    const { value } = this.state;

    return (
      <InputBox gridColumn={gridColumn} >
        <InputLabel className={this.props.error ? 'error' : ''}>{label}</InputLabel>
        <InputField
          name={id}
          value={value}
          className={`${theme} ${error ? 'error' : ''}`}
          placeholder={placeholder}
          onChange={this.onChange}
        />
      </InputBox>
    )
  }
}

Input.defaultProps = {
  placholder: '',
  theme: '',
  error: false,
};

export default Input;